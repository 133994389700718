html,
body,
#root {
    height: 100%;
}

a {
    text-decoration: none;
}

.header {
    border-top: 5px solid #3693cf;
    background-color: #212121;
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#333333), color-stop(85%, #262626), to(#0d0d0d));
    background-image: -webkit-linear-gradient(#333333, #262626 85%, #0d0d0d);
    background-image: -moz-linear-gradient(top, #333333, #262626 85%, #0d0d0d);
    background-image: -o-linear-gradient(#333333, #262626 85%, #0d0d0d);
    background-image: linear-gradient(#333333, #262626 85%, #0d0d0d);
    background-repeat: no-repeat;
    height: 70px;
    /* line-height: 70px; */
    /* vertical-align: middle; */
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.header .dashboard-logo {
    height: 30px;
    padding-left: 20px;
}

.header .avatar {
    position: absolute;
    right: 240px;
    top: 0;
}

.header .dashboard-logo img {
    height: 80%;
}

.header .mini-nav {
    display: flex;
    align-items: center;
    padding-right: 10px;
    /* line-height: 65px;
    position: absolute;
    right: 20px;
    top: 5px;
    background: rgba(0, 0, 0, 0); */
}

.header .mini-nav ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.header .mini-nav ul {
    list-style-type: none;
}

.header .mini-nav ul li {
    float: right;
    width: 65px;
    margin-top: 0;
    height: 65px;
    padding-top: 0;
    text-align: center;
    cursor: pointer;
}

.header .mini-nav ul li div {
    height: 65px;
    line-height: 65px;
    vertical-align: middle;
}

.header .mini-nav ul li div:hover {
    background-color: #585858;
}

.header .mini-nav ul li:first-child div {
    padding-top: 8px;
}

.header .mini-nav ul li:nth-child(2) div {
    padding-top: 5px;
}

.header .mini-nav ul li:nth-child(3) div {
    padding-top: 5px;
}

.container-fluid {
    background-color: #262626;
    min-height: calc(100vh - 105px);
    padding: 24px 20px;
    /* min-height: 100vh; */
}

.dashboard-wrapper {
    position: relative;
    background: #f7f7f7;
    -webkit-border-radius: 0 0 4px 4px;
    -moz-border-radius: 0 0 4px 4px;
    border-radius: 0 0 4px 4px;
    min-height: calc(100vh - 148px);
}

.top-nav {
    background-color: #262626;
    height: 60px;
}

.top-nav ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.top-nav ul li {
    width: 110px;
    float: left;
    margin-right: 1px;
}

.top-nav p {}

.top-nav ul li .nav-item {
    -webkit-border-radius: 4px 4px 0 0;
    -moz-border-radius: 4px 4px 0 0;
    border-radius: 4px 4px 0 0;
    background-color: #e4e4e4;
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#f2f2f2), color-stop(85%, #ebebeb), to(#cccccc));
    background-image: -webkit-linear-gradient(#f2f2f2, #ebebeb 85%, #cccccc);
    background-image: -moz-linear-gradient(top, #f2f2f2, #ebebeb 85%, #cccccc);
    background-image: -o-linear-gradient(#f2f2f2, #ebebeb 85%, #cccccc);
    background-image: linear-gradient(#f2f2f2, #ebebeb 85%, #cccccc);
    background-repeat: no-repeat;
    text-align: center;
    -webkit-transition: All 0.2s ease;
    -moz-transition: All 0.2s ease;
    -ms-transition: All 0.2s ease;
    -o-transition: All 0.2s ease;
    transition: All 0.2s ease;
    display: block;
    text-transform: uppercase;
    padding-top: 10px;
    color: #666666;
    font-size: 16px;
    height: 60px;
    padding-bottom: 10px;
}

.top-nav ul li .nav-item p {
    margin: 0;
}

.sub-nav-a {
    display: flex;
    flex-direction: row;
}

.top-nav ul li .nav-item.selected {
    background: #3693cf;
    color: #ffffff;
}

.top-nav ul li .nav-item:not(.selected):hover {
    color: #3693cf;
}

.sub-nav {
    height: 48px;
    line-height: 48px;
}

.sub-nav-wrapper {
    background-color: #262626;
}

.sub-nav ul {
    list-style-type: none;
    padding-left: 20px;
    padding-right: 20px;
}

.sub-nav ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    height: 48px;
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#3693cf), color-stop(85%, #3693cf), to(#1f5a80));
    background-image: -webkit-linear-gradient(#3693cf, #3693cf 85%, #1f5a80);
    background-image: -moz-linear-gradient(top, #3693cf, #3693cf 85%, #1f5a80);
    background-image: -o-linear-gradient(#3693cf, #3693cf 85%, #1f5a80);
    background-image: linear-gradient(#3693cf, #3693cf 85%, #1f5a80);
    background-repeat: no-repeat;
    -webkit-border-radius: 0 4px 0 0;
    -moz-border-radius: 0 4px 0 0;
    border-radius: 0 4px 0 0;
}

.sub-nav ul .sub-nav-item {
    height: 18px;
    padding: 2px 20px;
    font-size: 16px;
    line-height: 16px;
}

.sub-nav ul .sub-nav-item:hover {
    opacity: 0.5;
}

.content {
    min-height: calc(100vh - 276px);
    padding: 20px;
}

.footer {
    background: #3693cf;
    color: white;
    height: 50px;
    font-weight: normal;
    line-height: 50px;
    font-size: 11px;
    z-index: 1;
    -webkit-box-shadow: 0 0 15px #1f5a80 inset;
    -moz-box-shadow: 0 0 15px #1f5a80 inset;
    box-shadow: 0 0 15px #1f5a80 inset;
}

.footer p {
    color: white;
    height: 50px;
    font-weight: normal;
    line-height: 50px;
    font-size: 11px;
    margin: 0;
    padding: 0;
    text-align: center;
}

.userName {
    color: #ffffff;
    display: flex;
    flex-direction: row;
    margin-left: 10px;
    margin-right: 10px;
    font-size: 16px;
}