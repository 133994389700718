@charset "UTF-8";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 1920px;
  /* min-width: 1440px; */
  background-color: #262626 !important;


  /* background: url('../img/allback.png'); */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --primary-color: #5584ff;
  --secend-color: rgb(47, 60, 78);
  --text: #fff;
  --text2: #a1a1a1;
  --text3: #9b9b9b;
  --text4: #727272;
  --dark-text: #333;
  --text-aciive: #56bc8a;
  --header-height: 64px;
  --footer-height: 70px;
  --blog-header: 40px;
}

/* .g-body {
  background:
} */
@media screen and (max-width: 1980px) {
  html {
    font-size: 20px;
  }
}

@media screen and (max-width: 1440px) {
  html {
    font-size: 18px;
  }
}

@media screen and (max-width: 960px) {
  html {
    font-size: 16px;
  }
}

/* @media screen and (max-width: 600px) {
  html {
    font-size: 16px; }  */

@media screen and (max-width: 100px) {
  .hidden-1 {
    visibility: hidden;
    position: absolute;
    top: -999em;
    display: hidden !important;
  }
}

@media screen and (max-width: 200px) {
  .hidden-2 {
    visibility: hidden;
    position: absolute;
    top: -999em;
    display: hidden !important;
  }
}

@media screen and (max-width: 300px) {
  .hidden-3 {
    visibility: hidden;
    position: absolute;
    top: -999em;
    display: hidden !important;
  }
}

@media screen and (max-width: 400px) {
  .hidden-4 {
    visibility: hidden;
    position: absolute;
    top: -999em;
    display: hidden !important;
  }
}

@media screen and (max-width: 500px) {
  .hidden-5 {
    visibility: hidden;
    position: absolute;
    top: -999em;
    display: hidden !important;
  }
}

@media screen and (max-width: 600px) {
  .hidden-6 {
    visibility: hidden;
    position: absolute;
    top: -999em;
    display: hidden !important;
  }
}

@media screen and (max-width: 700px) {
  .hidden-7 {
    visibility: hidden;
    position: absolute;
    top: -999em;
    display: hidden !important;
  }
}

@media screen and (max-width: 800px) {
  .hidden-8 {
    visibility: hidden;
    position: absolute;
    top: -999em;
    display: hidden !important;
  }
}

@media screen and (max-width: 900px) {
  .hidden-9 {
    visibility: hidden;
    position: absolute;
    top: -999em;
    display: hidden !important;
  }
}

@media screen and (max-width: 1000px) {
  .hidden-10 {
    visibility: hidden;
    position: absolute;
    top: -999em;
    display: hidden !important;
  }
}

@media screen and (max-width: 1100px) {
  .hidden-11 {
    visibility: hidden;
    position: absolute;
    top: -999em;
    display: hidden !important;
  }
}

@media screen and (max-width: 1200px) {
  .hidden-12 {
    visibility: hidden;
    position: absolute;
    top: -999em;
    display: hidden !important;
  }
}

@media screen and (max-width: 1300px) {
  .hidden-13 {
    visibility: hidden;
    position: absolute;
    top: -999em;
    display: hidden !important;
  }
}

@media screen and (max-width: 1400px) {
  .hidden-14 {
    visibility: hidden;
    position: absolute;
    top: -999em;
    display: hidden !important;
  }
}

@media screen and (max-width: 1500px) {
  .hidden-15 {
    visibility: hidden;
    position: absolute;
    top: -999em;
    display: hidden !important;
  }
}

@media screen and (max-width: 1600px) {
  .hidden-16 {
    visibility: hidden;
    position: absolute;
    top: -999em;
    display: hidden !important;
  }
}

@media screen and (max-width: 1700px) {
  .hidden-17 {
    visibility: hidden;
    position: absolute;
    top: -999em;
    display: hidden !important;
  }
}

@media screen and (max-width: 1800px) {
  .hidden-18 {
    visibility: hidden;
    position: absolute;
    top: -999em;
    display: hidden !important;
  }
}

@media screen and (max-width: 1900px) {
  .hidden-19 {
    visibility: hidden;
    position: absolute;
    top: -999em;
    display: hidden !important;
  }
}

@media screen and (max-width: 2000px) {
  .hidden-20 {
    visibility: hidden;
    position: absolute;
    top: -999em;
    display: hidden !important;
  }
}